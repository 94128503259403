var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner",class:[
    'banner--' + _vm.orientation,
    { 'hide-on-mobile': _vm.hideMobile, 'hide-on-desktop': _vm.hideDesktop },
  ],style:({ ..._vm.foregroundColorCss, ..._vm.backgroundColorCss })},[_c('div',{staticClass:"banner__track",style:(_vm.transformTrack)},_vm._l((_vm.bannerTexts),function(bannerTextItem,index){return _c('span',{key:index,staticClass:"banner__track__text",class:{
        'hide-on-mobile': !!bannerTextItem.desktop && !!_vm.mobileText,
        'hide-on-desktop': !!bannerTextItem.mobile && !!_vm.desktopText,
      },domProps:{"innerHTML":_vm._s(bannerTextItem.text)}})}),0),_vm._v(" "),_c('div',{staticClass:"banner__track",style:(_vm.transformTrack)},_vm._l((_vm.bannerTexts),function(bannerTextItem,index){return _c('span',{key:index,staticClass:"banner__track__text",class:{
        'hide-on-mobile': !!bannerTextItem.desktop && !!_vm.mobileText,
        'hide-on-desktop': !!bannerTextItem.mobile && !!_vm.desktopText,
      },domProps:{"innerHTML":_vm._s(bannerTextItem.text)}})}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }
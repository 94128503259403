<template>
  <div
    class="banner"
    :class="[
      'banner--' + orientation,
      { 'hide-on-mobile': hideMobile, 'hide-on-desktop': hideDesktop },
    ]"
    :style="{ ...foregroundColorCss, ...backgroundColorCss }"
  >
    <div class="banner__track" :style="transformTrack">
      <span
        v-for="(bannerTextItem, index) in bannerTexts"
        :key="index"
        class="banner__track__text"
        :class="{
          'hide-on-mobile': !!bannerTextItem.desktop && !!mobileText,
          'hide-on-desktop': !!bannerTextItem.mobile && !!desktopText,
        }"
        v-html="bannerTextItem.text"
      />
    </div>
    <div class="banner__track" :style="transformTrack">
      <span
        v-for="(bannerTextItem, index) in bannerTexts"
        :key="index"
        class="banner__track__text"
        :class="{
          'hide-on-mobile': !!bannerTextItem.desktop && !!mobileText,
          'hide-on-desktop': !!bannerTextItem.mobile && !!desktopText,
        }"
        v-html="bannerTextItem.text"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Banner',
  props: {
    foregroundColor: {
      type: String,
      required: false,
      default: 'white',
    },
    foregroundColorHex: {
      type: String,
      required: false,
      default: '',
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'blue',
    },
    backgroundColorHex: {
      type: String,
      required: false,
      default: '',
    },
    mobileText: {
      type: [String, Array],
      required: false,
      default: '',
    },
    desktopText: {
      type: [String, Array],
      required: false,
      default: '',
    },
    orientation: {
      type: String,
      default: 'top',
    },
    hideDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    letterWidth: {
      type: Number,
      required: false,
      default: 9,
    },
  },
  data() {
    return {
      translateX: { value: 0 },
      translateY: { value: 0 },
      marqueeAnimationDesktop: null,
      marqueeAnimationMobile: null,
      elementPixelLength: 3000,
      resizeObserver: null,
    }
  },
  computed: {
    foregroundColorCss() {
      return {
        '--foreground-color':
          this.foregroundColorHex || `var(--${this.foregroundColor})`,
      }
    },
    backgroundColorCss() {
      return {
        '--background-color':
          this.backgroundColorHex || `var(--${this.backgroundColor})`,
      }
    },
    bannerTexts() {
      const output = []
      for (let i = 0; i < this.numTextElementsMobile; i++) {
        if (this.mobileText) {
          if (typeof this.mobileText === 'string') {
            output.push({ text: this.mobileText, mobile: true })
          } else {
            this.mobileText.forEach((text) => {
              output.push({ text, mobile: true })
            })
          }
        }
      }
      for (let i = 0; i < this.numTextElementsDesktop; i++) {
        if (this.desktopText) {
          if (typeof this.desktopText === 'string') {
            output.push({ text: this.desktopText, desktop: true })
          } else {
            this.desktopText.forEach((text) => {
              output.push({ text, desktop: true })
            })
          }
        }
      }
      return output
    },
    rotationZ() {
      if (this.left) return 180
      return 0
    },
    animeTarget() {
      if (this.orientation === 'top' || this.orientation === 'bottom')
        return this.translateX
      if (this.orientation === 'left' || this.orientation === 'right')
        return this.translateY
      return null
    },
    transformTrack() {
      return {
        transform: `rotateZ(${this.rotationZ}deg) translate3D(${this.translateX.value}%, ${this.translateY.value}%, 0px)`,
      }
    },
    horizontal() {
      return this.orientation === 'top' || this.orientation === 'bottom'
    },
    vertical() {
      return this.orientation === 'left' || this.orientation === 'right'
    },
    numTextElementsDesktop() {
      let res = 0
      let denom = 0

      if (typeof this.desktopText === 'object') {
        this.desktopText.forEach((text) => {
          denom += text?.length
        })
      } else {
        denom = this.desktopText?.length
      }
      res = Number.parseInt(
        this.elementPixelLength / (this.letterWidth * denom)
      )
      if (res < 1) res = 1
      return res
    },
    numTextElementsMobile() {
      let res = 0
      let denom = 0

      if (typeof this.mobileText === 'object') {
        this.mobileText.forEach((text) => {
          denom += text?.length
        })
      } else {
        denom = this.mobileText.length
      }
      res = Number.parseInt(
        this.elementPixelLength / (this.letterWidth * denom)
      )
      if (res < 1) res = 1
      return res
    },
  },
  mounted() {
    this.initResizeObserver()
    this.initAnime()
    this.marqueeAnimationDesktop.play()
  },
  methods: {
    initAnime() {
      this.marqueeAnimationDesktop = this.$anime({
        targets: this.animeTarget,
        easing: 'linear',
        duration: 30000,
        value: [0, 100],
        direction: this.orientation === 'top' ? 'reverse' : 'normal',
        loop: true,
      })
    },
    setElementPixelLength() {
      if (this.vertical) this.elementPixelLength = this.$el.offsetHeight
      else this.elementPixelLength = this.$el.offsetWidth
    },
    initResizeObserver() {
      this.resizeObserver = new ResizeObserver(this.resizeObserverHandler)
      this.resizeObserver.observe(this.$el)
    },
    resizeObserverHandler() {
      this.setElementPixelLength()
    },
  },
}
</script>
<style lang="scss" scoped>
.banner {
  @include p--small;

  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--foreground-color);
  background-color: var(--background-color);
  overflow: hidden;

  .banner__track {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .banner__track__text {
    @include caps('large');

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    white-space: nowrap;

    p {
      margin: 0;
    }
  }

  &.banner--left,
  &.banner--right {
    .banner__track__text {
      padding: spacing('xxsmall') 0;
    }
  }

  &.banner--top,
  &.banner--bottom {
    .banner__track__text {
      padding: 0 spacing('xxsmall');
    }
  }

  &.banner--left {
    top: 0;
    left: 0;
    width: spacing('medium');
    height: 100%;
    writing-mode: vertical-rl;
    transform: rotateZ(180deg);
    z-index: 1;
  }

  &.banner--right {
    top: 0;
    right: 0;
    width: spacing('medium');
    height: 100%;
    writing-mode: vertical-lr;
    z-index: 1;
  }

  &.banner--bottom {
    top: unset;
    bottom: 0;
    width: 100%;
    height: spacing('medium');
    z-index: 2;
  }

  &.banner--top {
    top: 0;
    width: 100%;
    height: spacing('medium');
    z-index: 2;
  }
}
</style>
